/* 
Match Info
Match Stats
*/

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import store from "@/store/index.js";
import i18n from "@/i18n.js";

import { getAPI, parsedQueryString } from "@/api/config";
import {
  getUrlResizedRatio,
  getMediaCenterUrl,
} from "@/api/adjust_mediacenter_url";

Vue.use(VueAxios, axios);

const getMatchInfo = async (idMatch) =>
  parseMatchInfo(await getAPI("/Matches/matchlite" + `?idMatch=${idMatch}`));

const getCoachAndReferee = async (idMatch) =>
  parseCoachAndReferee(
    await getAPI("/Matches/matchlite" + `?idMatch=${idMatch}`)
  );

const getMatchStats = async (idMatch, period) =>
  parseMatchStats(
    await getAPI(
      "/Boxscore/teammatchstatistics" +
        `?idMatch=${idMatch}` +
        (period ? `&period=${period}` : "")
    )
  );

const getPlayersStats = async (params) =>
  parsePlayersStats(
    await getAPI("/Boxscore/playermatchstatistics" + parsedQueryString(params))
  );

const getMatchTotalStats = async (idMatch, period) =>
  parseMatchTotalsStats(
    await getAPI(
      "/Boxscore/teammatchstatistics" +
        `?idMatch=${idMatch}` +
        (period ? `&period=${period}` : "")
    )
  );

const getMatchGlobalsStats = async (idMatch) =>
  idMatch
    ? parseMatchGlobalsStats(
        await getAPI("/Boxscore/teammatchstatistics" + `?idMatch=${idMatch}`)
      )
    : "";

const getLeaders = async (idMatch, period) =>
  parseLeaders(
    await getAPI(
      "/Boxscore/playermatchstatistics" +
        `?idMatch=${idMatch}` +
        (period ? `&period=${period}` : "")
    )
  );

const getMatchVersusStats = async (idMatch, period) =>
  idMatch
    ? parseMatchVersusStats(
        await getAPI(
          "/Boxscore/teammatchstatistics" +
            `?idMatch=${idMatch}` +
            (period ? `&period=${period}` : "")
        )
      )
    : "";

const getMatchScoreDev = async (idMatch) =>
  parseMatchScoreDev(
    await getAPI("/PlayByPlay/markerbreakdown" + `?idMatch=${idMatch}`)
  );

const getMapshot = async (idMatch, period) =>
  parseMapshot(
    await getAPI(
      "/PlayByPlay/shotsbreakdown" +
        `?idMatch=${idMatch}` +
        (period ? `&period=${period}` : "")
    )
  );

const getPlaybyplays = async (idMatch) =>
  parsePlaybyplays(
    await getAPI(
      "/PlayByPlay/matchevents" + `?idMatch=${idMatch}&jvFilter=true`
    )
  );

const getPreMatchVersusStats = async (params) => {
  params["idClub"] = params.idClub1;
  let local_response = await getAPI(
    "/Statistics/club/seasonaverages" + parsedQueryString(params),
    "openapi"
  );
  const local_team_stats = parseVersusStats(local_response[0], true);

  params["idClub"] = params.idClub2;
  let visiting_response = await getAPI(
    "/Statistics/club/seasonaverages" + parsedQueryString(params),
    "openapi"
  );
  const visiting_team_stats = parseVersusStats(visiting_response[0], true);

  return {
    local_team: local_team_stats,
    visiting_team: visiting_team_stats,
  };
};

const getPreMatchSeasonLeaders = async (params) =>
  await getAPI(
    "/Statistics/competition/rankingplayersaverage" + parsedQueryString(params),
    "openapi"
  );

export default {
  getMatchInfo,
  getMatchStats,
  getPlayersStats,
  getMatchTotalStats,
  getMatchGlobalsStats,
  getLeaders,
  getMatchVersusStats,
  getMatchScoreDev,
  getMapshot,
  getPlaybyplays,
  getCoachAndReferee,
  getPreMatchVersusStats,
  getPreMatchSeasonLeaders,
  parsePreMatchSeasonLeaders,
};

function parseMatchInfo(data) {
  if (data) {
    for (let img of data.team1.club.media) {
      if (img.type == "foto_equipo") {
        data["arena_image"] = getUrlResizedRatio(img, img.id_type);
      }
      if (img.type == "logo") {
        data["local_image"] = getUrlResizedRatio(img, img.id_type);
      }
      if (img.type == "logo_negativo") {
        data["local_negative_image"] = getUrlResizedRatio(img, img.id_type);
      }
    }
    for (let img of data.team2.club.media) {
      if (img.type == "logo") {
        data["visitor_image"] = getUrlResizedRatio(img, img.id_type);
      }
      if (img.type == "logo_negativo") {
        data["visitor_negative_image"] = getUrlResizedRatio(img, img.id_type);
      }
    }
    if (!data.arena_image) {
      // DEFAULT ARENA IMG
      data["arena_image"] = "/default-arena.jpg";
    }
    data.period_marker = data.period_marker.sort((a, b) =>
      a.quarter > b.quarter ? 1 : -1
    );
    if (data.local_points == null) {
      data.local_points = 0;
      data.visitor_points = 0;
    }
  }
  return data;
}

function parseMatchStats(data) {
  let localFouls = 0;
  let visitorFouls = 0;
  for (let team of data) {
    if (team.is_local) {
      localFouls = team.personal_fouls;
    } else {
      visitorFouls = team.personal_fouls;
    }
  }
  return {
    localFouls: localFouls,
    visitorFouls: visitorFouls,
  };
}

function addPlayerShot(shots, shot, team) {
  if (!(shot.posX + shot.posY)) {
    if (shot.id_playbyplaytype == 100) {
      shot.success = true;
    } else {
      return shots;
    }
  }
  let obj = {
    scored: shot.success,
    dunk: shot.id_playbyplaytype == 100,
    x: Number,
    y: Number,
  };
  if (team == "visitor_team") {
    (obj.x = 495 - (shot.posX / 26425 + 0.03) * 495),
      (obj.y = ((shot.posY + 7500) / 15000 + 0.065) * 250);
  } else {
    (obj.x = (shot.posX / 26425 + 0.05) * 495),
      (obj.y = ((-shot.posY + 7500) / 15000 + 0.065) * 250);
  }
  let listed_players = Object.keys(shots[shot.period - 1][team]);
  if (listed_players.includes(shot.license.licenseAbbrev)) {
    shots[shot.period - 1][team][shot.license.licenseAbbrev]["shots"].push(obj);
  } else {
    shots[shot.period - 1][team][shot.license.licenseAbbrev] = {};
    shots[shot.period - 1][team][shot.license.licenseAbbrev]["num"] =
      shot.shirt_number;
    shots[shot.period - 1][team][shot.license.licenseAbbrev]["score"] =
      shot.shirt_number;
    shots[shot.period - 1][team][shot.license.licenseAbbrev]["shots"] = [];
    shots[shot.period - 1][team][shot.license.licenseAbbrev]["shots"].push(obj);
  }
  return shots;
}

function parseMapshot(data) {
  let shots = [];
  let quarterNum = store.state.match.quarterNum;
  if (quarterNum) {
    for (let i = 0; i < quarterNum; i++) {
      shots.push({ local_team: {}, visitor_team: {} });
    }
    for (let shot of data) {
      if (shot.id_team != store.state.match.visitingId) {
        shots = addPlayerShot(shots, shot, "local_team");
      } else {
        shots = addPlayerShot(shots, shot, "visitor_team");
      }
    }
  }
  return shots;
}

function parsePlaybyplays(data) {
  let pbp = [];
  for (let play of data) {
    if (play) {
      // Select face image
      let playerImg = "";
      if (play.license) {
        if (play.license.media) {
          for (let img of play.license.media) {
            if (img != null) {
              if (img.id_type == 5) {
                playerImg = getUrlResizedRatio(img, img.id_type);
              }
            }
          }
        }
      }
      if (playerImg == "") playerImg = "/jugador-desconocido.jpg";
      // Select type of event
      let eventType = "";
      if (play.id_license) {
        eventType = "player";
      } else if (play.id_team) {
        eventType = "team";
      } else {
        eventType = "match";
      }

      let obj = {
        playByPlayDescripcion: play.type.description,
        crono: play.crono.slice(3, 8),
        periodo: play.period,
        id_license: play.id_license,
        idPlayByPlayType: play.id_playbyplaytype,
        tipo: {
          descripcion: eventType,
        },
        local: play.id_team ? play.local : null,
        puntosLocal: play.score_local,
        puntosVisitante: play.score_visitor,
        player: {
          name: play.license ? play.license.licenseNick : "",
          img: playerImg,
        },
        order: play.order,
        statistics: play.statistics,
      };
      // Ignore start and end player movements -> Not needed (bug)
      // if (
      //   !(play.minute == 40 && play.second == 0 && play.id_playbyplaytype == 115) &&
      //   !(play.minute == 0 && play.second == 0 && play.id_playbyplaytype == 112)
      // )
      pbp.push(obj);
    }
  }
  return pbp.reverse();
}

function getPlayerImg(media, type = 5) {
  for (let img of media) {
    if (img != null) {
      if (img.id_type == type) {
        return getMediaCenterUrl(img);
      }
    }
  }
  return null;
}

function parsePlayersStats(data) {
  let local_team = [];
  let visiting_team = [];
  let aux_player = {};
  let local_global = {};
  let visiting_global = {};
  for (let player of data) {
    if (player.license) {
      aux_player = {
        id: player.license.id,
        image: getPlayerImg(player.license.media),
        local: player.is_local,
        cinco_inicial: player.starting,
        dorsal: player.pno,
        nombre: player.license.licenseAbbrev,
        jugando: player.playing,
        tiempo_jugado: player.time_played,
        puntos: +player.points,
        t2: `${player["2pt_success"]}/${player["2pt_tried"]}`,
        t2p: player["2pt_percentage"],
        t3: `${player["3pt_success"]}/${player["3pt_tried"]}`,
        t3p: player["3pt_percentage"],
        t1: `${player["1pt_success"]}/${player["1pt_tried"]}`,
        t1p: player["1pt_percentage"],
        ro: player.offensive_rebound,
        rd: +player.defensive_rebound,
        rt: +player.total_rebound,
        asis: +player.asis,
        bp: +player.turnovers,
        br: +player.steals,
        tc: +player.blocks,
        tr: +player.received_blocks,
        mates: +player.dunks,
        fp: +player.personal_fouls,
        fr: +player.received_fouls,
        masmenos: +player.differential,
        val: +player.val,
        dorsal_num: +player.pno,
        periodo: player.period,
      };
      if (player.is_local) {
        local_team.push(aux_player);
      } else {
        visiting_team.push(aux_player);
      }
    } else {
      aux_player = {
        id: "team",
        local: player.is_local,
        tiempo_jugado: "",
        puntos: +player.points,
        t2: `${player["2pt_success"]}/${player["2pt_tried"]}`,
        t2p: player["2pt_percentage"],
        t3: `${player["3pt_success"]}/${player["3pt_tried"]}`,
        t3p: player["3pt_percentage"],
        t1: `${player["1pt_success"]}/${player["1pt_tried"]}`,
        t1p: player["1pt_percentage"],
        ro: player.offensive_rebound,
        rd: +player.defensive_rebound,
        rt: +player.total_rebound,
        asis: +player.asis,
        bp: +player.turnovers,
        br: +player.steals,
        tc: +player.blocks,
        tr: +player.received_blocks,
        mates: +player.dunks,
        fp: +player.personal_fouls,
        fr: +player.received_fouls,
        masmenos: +player.differential,
        val: +player.val,
        dorsal_num: +player.pno,
        periodo: player.period,
      };
      if (player.is_local) {
        local_global = aux_player;
      } else {
        visiting_global = aux_player;
      }
    }
  }
  return {
    local_team: local_team,
    visiting_team: visiting_team,
    local_global: local_global,
    visiting_global: visiting_global,
  };
}

function parseMatchTotalsStats(data) {
  let local_team = [];
  let visiting_team = [];
  let aux_team = {};
  for (let team of data) {
    aux_team = {
      id: team.id_team,
      local: team.is_local,
      tiempo_jugado: "",
      puntos: +team.points,
      t2: `${team["2pt_success"]}/${team["2pt_tried"]}`,
      t2p: team["2pt_percentage"],
      t3: `${team["3pt_success"]}/${team["3pt_tried"]}`,
      t3p: team["3pt_percentage"],
      t1: `${team["1pt_success"]}/${team["1pt_tried"]}`,
      t1p: team["1pt_percentage"],
      ro: team.offensive_rebound,
      rd: +team.defensive_rebound,
      rt: +team.total_rebound,
      asis: +team.asis,
      bp: +team.turnovers,
      br: +team.steals,
      tc: +team.blocks,
      tr: +team.received_blocks,
      mates: +team.dunks,
      fp: +team.personal_fouls,
      fr: +team.received_fouls,
      masmenos: +team.differential / 5,
      val: +team.val,
      periodo: team.period,
    };
    if (team.is_local) {
      aux_team["image"] = team.local_team.media;
      local_team.push(aux_team);
    } else {
      aux_team["image"] = team.visitor_team.media;
      visiting_team.push(aux_team);
    }
  }
  return { local_team: local_team[0], visiting_team: visiting_team[0] };
}

function parseMatchGlobalsStats(data) {

  let local_team = [];
  let visiting_team = [];
  let aux_team = {};
  for (let team of data.list) {
    aux_team = {
      id: team.id_team,
      local: team.is_local,
      puntos: +team.points,
      t2: `${team["2pt_success"]}/${team["2pt_tried"]}`,
      t2p: team["2pt_pertentage"],
      t3: `${team["3pt_success"]}/${team["3pt_tried"]}`,
      t3p: team["3pt_pertentage"],
      t1: `${team["1pt_success"]}/${team["1pt_tried"]}`,
      t1p: team["1pt_pertentage"],
      ro: team.ofensive_rebound,
      rd: +team.defensive_rebound,
      rt: +team.total_rebound,
      asis: +team.asis,
      bp: +team.turnovers,
      br: +team.steals,
      tc: +team.blocks,
      tr: +team.received_blocks,
      mates: +team.dunks,
      fp: +team.personal_fouls,
      fr: +team.received_fouls,
      masmenos: +team.differential,
      val: +team.val,
      periodo: team.period,
    };
    if (team.is_local) {
      aux_team["image"] = team.local_team.media;
      local_team.push(aux_team);
    } else {
      aux_team["image"] = team.visitor_team.media;
      visiting_team.push(aux_team);
    }
  }
  return { local_team: local_team[0], visiting_team: visiting_team[0] };
}

function parseMatchScoreDev(data) {
  let score_dev = {};
  for (let i = 1; i <= 4; i++) {
    score_dev[i + i18n.t("Q")] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
  }

  if (data) {
    for (let score of data) {
      var period_id = score.period + i18n.t("Q");
      if (score.period > 4) {
        period_id = score.period + i18n.t("OT");
      }

      // Init overtime periods
      if (score.period > 4 && !score_dev[period_id]) {
        score_dev[period_id] = [0, 0, 0, 0];
      }

      var period_index = 9 - score.minute;
      if (score.period > 4) {
        period_index = 4 - score.minute;
      }

      score_dev[period_id][period_index] = score.score_differential;
    }
  }
  return score_dev;
}

function order(stat, players) {
  players.sort((a, b) => {
    if (a[stat] > b[stat]) {
      return -1;
    }
    if (a[stat] < b[stat]) {
      return 1;
    }
    if (a[stat] == b[stat]) {
      if (a["nombre"] > b["nombre"]) {
        return 1;
      } else {
        return -1;
      }
    }
    return 0;
  });
  return players;
}

function parseTop5(stat, players) {
  let top = [];
  for (let player of players) {
    top.push({
      num: player.pno ?? "",
      name: player.license.licenseAbbrev
        ? player.license.licenseAbbrev
        : player.license.licenseAbrev
        ? player.license.licenseAbrev
        : "",
      val: player[stat],
      image: player.license.media ? getPlayerImg(player.license.media, 1) : "",
    });
  }
  return top;
}

function getTop5(stat, stat_name, stat_abbrev, local, visitor) {
  return {
    name: stat_name,
    abbrev: stat_abbrev,
    local_team: parseTop5(stat, order(stat, local).slice(0, 5)),
    visiting_team: parseTop5(stat, order(stat, visitor).slice(0, 5)),
  };
}

function parseLeaders(data) {
  let local_team = [];
  let visiting_team = [];
  for (let player of data) {
    if (player.license) {
      if (player.is_local) {
        local_team.push(player);
      } else {
        visiting_team.push(player);
      }
    }
  }
  return {
    val: getTop5("val", "Valoración", "Val", local_team, visiting_team),
    score: getTop5("points", "Puntos", "Pun", local_team, visiting_team),
    rb: getTop5("total_rebound", "Rebotes", "Reb", local_team, visiting_team),
    asis: getTop5("asis", "Asistencias", "Ass", local_team, visiting_team),
  };
}

function parsePreMatchSeasonLeaders(local_team, visiting_team) {
  return {
    val: getTop5("val", "Valoración", "Val", local_team, visiting_team),
    score: getTop5("points", "Puntos", "Pun", local_team, visiting_team),
    rb: getTop5("total_rebound", "Rebotes", "Reb", local_team, visiting_team),
    asis: getTop5("asis", "Asistencias", "Ass", local_team, visiting_team),
  };
}

function parseSec2Min(sec) {
  let min = Math.floor(sec / 60);
  let s = sec % 60;
  let parsedMin = min < 10 ? "0" + min : min;
  let parsedSec = s < 10 ? "0" + s : s;
  return parsedMin + ":" + parsedSec;
}

function parseVersusStats(team, prematch = false) {
  let d = 0;
  if (prematch) {
    d = 1;
  }
  if (team != undefined) {
    let t2a = "2pt_success" in team ? team["2pt_success"] : team.t2a;
    let t2t = "2pt_tried" in team ? team["2pt_tried"] : team.t2t;
    let t2p = "2pt_percentage" in team ? team["2pt_percentage"] : team.t2p;

    let t3a = "3pt_success" in team ? team["3pt_success"] : team.t3a;
    let t3t = "3pt_tried" in team ? team["3pt_tried"] : team.t3t;
    let t3p = "3pt_percentage" in team ? team["3pt_percentage"] : team.t3p;

    return {
      points: {
        name: "Points scored",
        c: parseNumber(team.points, d),
      },
      received_points: {
        name: "Points received",
        c: parseNumber(team.received_points, d),
      },
      t1: {
        name: "Free throw",
        c: parseNumber(
          "1pt_success" in team ? team["1pt_success"] : team.t1a,
          d
        ),
        i: parseNumber("1pt_tried" in team ? team["1pt_tried"] : team.t1t, d),
        p: parseNumber(
          "1pt_percentage" in team ? team["1pt_percentage"] : team.t1p,
          d
        ),
      },
      t2: {
        name: "Two-points",
        c: parseNumber(t2a, d),
        i: parseNumber(t2t, d),
        p: parseNumber(t2p, d),
      },
      t3: {
        name: "Three-points",
        c: parseNumber(t3a, d),
        i: parseNumber(t3t, d),
        p: parseNumber(t3p, d),
      },
      field: {
        name: "Field goals",
        c: parseNumber(t3a + t2a, d),
        i: parseNumber(t3t + t2t, d),
        p: parseNumber(((t3a + t2a) / (t3t + t2t)) * 100, d),
      },
      total_rebound: {
        name: "Rebounds",
        c: parseNumber(team.total_rebound, d),
      },
      defensive_rebound: {
        name: "Rebotes Defensivos",
        c: parseNumber(team.defensive_rebound, d),
      },
      offensive_rebound: {
        name: "Rebotes Ofensivos",
        c: parseNumber(team.offensive_rebound, d),
      },
      personal_fouls: {
        name: "Personal fouls",
        c: parseNumber(team.personal_fouls, d),
      },
      timeouts: {
        name: "Timeouts",
        c: parseNumber(team.timeouts, d),
      },
      best_streak: {
        name: "Best streak",
        c: parseNumber(team.best_streak, d),
      },
      time_as_leader: {
        name: "Time as leader",
        t: parseSec2Min(team.time_as_leader),
        c: parseNumber(team.time_as_leader, d),
      },
      leader_changes: {
        name: "Leader changes",
        c: parseNumber(team.leader_changes, d),
      },
      maximun_difference: {
        name: "Maximum difference",
        c: parseNumber(team.maximun_difference, d),
      },
      asis: {
        name: "Asistencias",
        c: parseNumber(team.asis, d),
      },
      steals: {
        name: "Steals",
        c: parseNumber("steals" in team ? team.steals : team.stolen_balls, d),
      },
      turnovers: {
        name: "Turnovers",
        c: parseNumber("steals" in team ? team.turnovers : team.lost_balls, d),
      },
      points_after_steal: {
        name: "Points after steal",
        c: parseNumber(team.points_after_steal, d),
      },
      points_in_the_paint: {
        name: "Points in the paint",
        c: parseNumber(team.points_in_the_paint, d),
      },
      second_opportunity_points: {
        name: "Second chance points",
        c: parseNumber(team.second_opportinity_points, d),
      },
      counter_attack: {
        name: "Counter attack",
        c: parseNumber(team.counter_attack, d),
      },
      bench_points: {
        name: "Bench points",
        c: parseNumber(team.bench_points, d),
      },
      blocks: {
        name: "Tapones",
        c: parseNumber(team.blocks, d),
      },
      dunks: {
        name: "Dunks",
        c: parseNumber(team.dunks, d),
      },
      val: {
        name: "Valoración",
        c: parseNumber(team.val, d),
      },
    };
  }
}

function parseNumber(num, d) {
  if (typeof num === "number") {
    return (
      Math.round((num + Number.EPSILON) * Math.pow(10, d)) / Math.pow(10, d)
    );
  }
  return num;
}

function parseMatchVersusStats(data) {
  let local = [];
  let visitor = [];
  for (let team of data) {
    if (team.is_local) {
      local = parseVersusStats(team);
    } else {
      visitor = parseVersusStats(team);
    }
  }
  return {
    local_team: local,
    visiting_team: visitor,
  };
}

function order_referee(a, b) {
  if (a.license_policy < b.license_policy) {
    return -1;
  }
  if (a.license_policy > b.license_policy) {
    return 1;
  }
  return 0;
}

function parseCoachAndReferee(data) {
  let coaches = {
    local: {
      coach: {},
      assistants: [],
    },
    visitor: {
      coach: {},
      assistants: [],
    },
  };
  if (data.team1.coaches) {
    for (let c of data.team1.coaches) {
      if (c.id_License_subtype == 8) coaches.local.coach = c;
      else coaches.local.assistants.push(c);
    }
  }
  if (data.team2.coaches) {
    for (let c of data.team2.coaches) {
      if (c.id_License_subtype == 8) coaches.visitor.coach = c;
      else coaches.visitor.assistants.push(c);
    }
  }
  return { coaches: coaches, referees: data.referee.sort(order_referee) };
}