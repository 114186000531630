<template lang="pug">

.nav(
	v-show="displayNav"
	:class="[{'nav--day': isShowingDays}, {'nav--round': isShowingRounds}, {'nav--app': $store.state.general.isApp},{'nav--widget': isWidget}]")
	NavTop(:isWidget = "isWidget")
	.nav-cont-wrap(v-if="!loading")
		transition(name="fade")
			StandingsTable(
				v-if="show_standings"
				:standings = "standings"
				v-on-clickaway="clickOutStandings"
				)
		transition(name="fade")
			NavDay(
				v-if = "nav_state == 1" 
				v-show="!show_standings"
				:match_list = "match_list"
				:isWidget = "this.$props.isWidget"
				ref="childComponent"
			)
		transition(name="fade")
			NavRound(
				v-if = "nav_state == 2 && selectorRoundWeek == 'round'"
				v-show="!show_standings"
				:round_list = "round_list"
				:actual = "actual"
			)
		transition(name="fade")
			NavWeek(
				v-if = "nav_state == 2 && selectorRoundWeek == 'week'"
				v-show="!show_standings"
				:week_list = "week_list"
				:actual = "actual"
			)
		transition(name="fade")
			NavCompetition(
				v-if = "nav_state == 3"
				:competition_list = "competition_list"
				:actual = "actual"
			)
		transition(name="fade")
			NavYear(
				v-if = "nav_state == 4"
				:year_list = "year_list"
				:actual = "actual"
			)
	.nav-cont-wrap(v-else)
		Loading

</template>

<style lang="sass">

.nav
	--tb-height-big: 80px
	--tb-height-day: 93px
	--tb-height: 55px
	--tb-sec-height: 35px
	
	position: fixed
	top: 0
	bottom: 0
	width: 100%
	z-index: 4
	box-shadow: 0 0 3px 0px $black
	background-color: $white
	height: 100%
	
	+breakpoint(medium)
		position: absolute
		max-width: 360px
		min-width: 280px
	+breakpoint(large)
		position: initial
	
	&--round
		.nav-top
			height: var(--tb-height-big)
		.nav-cont-wrap
			height: calc(100% - var(--tb-height-big))
			margin-top: var(--tb-height-big)
			+breakpoint(medium)
				height: calc(100vh - var(--tb-height-big))
				margin-top: 0px
	&--day
		.nav-top
			height: var(--tb-height-day)
		.nav-cont-wrap
			height: calc(100% - var(--tb-height-day))
			margin-top: var(--tb-height-day)
			+breakpoint(medium)
				height: calc(100vh - var(--tb-height-day))
				margin-top: 0px
	&--app
		.nav-top
			padding-top: 3.2rem
			&-pri-opt
				height: 30px
		// .nav-cont, .st
		// 	padding-bottom: 10rem 
			
		.nav-cont-wrap
			height: calc(100% - (var(--tb-height) + 3.2rem))
			margin-top: calc(var(--tb-height) + 3.2rem)
			
			+breakpoint(medium)
				height: calc(100vh - (var(--tb-height) + 3.2rem))
				margin-top: 0px
		
		
		&.nav--day, &.nav--round
			.nav-cont-wrap
				height: calc(100% - (var(--tb-height-big) + 3.2rem))
				margin-top: calc(var(--tb-height-big) + 3.2rem)
				+breakpoint(medium)
					height: calc(100vh - (var(--tb-height-big) + 3.2rem))
					margin-top: 0px
	
	&--widget
		display: inline
		max-width: initial
		width: 100%
		box-shadow: initial
		// background-color: transparent
		position: initial
		&.nav--day
			.nav-top
				grid-template-rows: 1fr
				height: 45px
				box-shadow: initial
				position: initial
				&-wrap
					width: 100%
					display: flex
					justify-content: center
					background-color: $black

		.nav-cont
			min-height: initial
			height: initial
			display: grid
			grid-auto-flow: column
			overflow: initial
			overflow-x: auto
			position: initial
			padding: 0
			grid-gap: 0
			
			@media (hover: hover) and (pointer: fine)
				&:hover
					.nav-cont-arrow
						opacity: 0.4
						&:hover
							opacity: 1
							color: $orange

			&-wrap
				position: relative
				height: initial
				min-height: 150px
				overflow: initial
				display: flex
				justify-content: center
				align-items: center
				margin-top: 0
				
				&:before
					content: none
				.lo
					align-items: center
					padding-top: 0

			&-arrow
				position: absolute
				cursor: pointer
				color: $black
				height: 100%
				justify-content: center
				align-items: center
				z-index: 2
				opacity: 0
				transition: .2s
				font-size: 1.8em
				bottom: 0
				padding: 0 1.5em
				// box-shadow: 0 0 3px 0px $black
				display: none
				+breakpoint(medium)
					display: flex



				&--left
					left: 0
				&--right
					right: 0

		.nav-day
			display: grid
			box-sizing: border-box
			grid-template-rows: auto 60px
			height: 100%
			position: relative
			padding: 0
			grid-gap: 2.5em
			padding: 2.5em 0 4em 0

			cursor: initial
			justify-items: start

			+breakpoint(medium)
				padding: 1.5em 0 3em 0
				grid-gap: 1.5em

			&:not(:first-child)
				&:before
					content: ""
					width: 1px
					height: calc(100% - 3em)
					background-color: darken($light-grey,10)
					position: absolute
					left: 0
					top: 50%
					transform: translateY(-50%)


			&-match
				display: contents
				&:before
					content: none
				&-wrap
					display: grid
					grid-auto-columns: 350px
					grid-auto-flow: column
					align-content: end
					+breakpoint(medium)
						grid-auto-columns: 350px

					& > *
						&:last-child
							.ge-match
								&:before
									content: none
			&__date
				padding: 0 1em
				line-height: 1em
				position: sticky
				left: 0

		.ge-match
			&--nav
				// border-right: 1px solid $light-grey
				height: 100%
				// padding: 0 2rem
				box-sizing: border-box
				position: relative
				cursor: pointer

				@media (hover: hover) and (pointer: fine)
					&:hover
						&::before
							opacity: 1
						color: $black
						.ge-match-time-info
							transition: all 0.2s linear
							color: $black


				&:before
					content: ""
					width: 1px
					height: 100%
					background-color: $light-grey
					position: absolute
					right: 0
					top: 50%
					transform: translateY(-50%)

				.ge-match-info
					&-wrap
						max-width: initial
					&__score
						width: 48px
						height: 32px
						flex-basis: initial

					&-shield
						padding: 0 1em


		// 		.ge-match-info
		// 			&-shield
		// 				flex-basis: initial
		// 				height: 100%
		// 				img
		// 					height: 100%
		// 					max-height: initial

		// .nav-test
		// 	background-color: blue
		// 	height: 100%




	&-top
		width: 100%
		display: grid
		position: fixed
		top: 0x
		grid-auto-flow: row
		border: none
		color: $black
		background: $black
		box-shadow: 0 0 5px 0px $black
		z-index: 3
		max-width: $global-width-wrap * (7/12)
		height: var(--tb-height)
		align-content: center
		+breakpoint(medium)
			position: sticky

		@at-root .nav--day &
			grid-template-rows: max-content 1fr


		&-pri
			display: grid
			grid-auto-flow: column
			grid-template-columns: 1fr max-content
			justify-content: space-between
			padding: 0
			align-items: center
			height: 45px

			
			&-opt
				color: $white
				display: grid
				grid-auto-flow: column
				grid-gap: 0.7rem
				font-weight: $bold
				cursor: pointer
				box-sizing: border-box
				height: 45px
				align-content: center
				align-items: center
				justify-self: start

				@at-root .nav--round &
					grid-template-columns: 20px max-content max-content
					align-items: end
				@at-root .nav--day &
					grid-template-columns: 20px max-content max-content
					align-items: end
				
				&__menu
					width: 100%

				&__back
					padding:
						left: 1rem
						bottom: 0.1rem
				&__comp
					max-height: 25px
					max-width: 120px


				&__year
					font-size: 1em
					line-height: 1em
					@at-root .nav--round &
						padding-bottom: 0.2em

					@at-root .nav--day &
						padding-bottom: 0.2em
				
					&--label
						font-weight: $regular
						padding-left: 1rem

				
				&--center
					align-items: center

			&__calendar
				color: $white
				font-size: 1.1rem
			&__stand
				+btn
				height: 100%
				line-height: 1rem
				padding: 0 1rem
				
					
				justify-content: center	
				
				@at-root .nav--app.nav--round &
					padding-top: 3.5rem
				
				@at-root .nav--app.nav--day &
					padding-top: 3.5rem
					padding-bottom: var(--tb-sec-height)

				// padding: 0 2rem

		&-sec
			&-round, &__close_stan
				color: $white
				cursor: pointer
				align-self: center
				display: grid
				grid-auto-flow: column
				grid-gap: 0.5rem
				padding-left: 1rem
				align-items: center
				font-size: 0.8em
				text-transform: initial
				font-weight: $bold
				&-txt
					font-size: 1.4em
					&__leg
						font-weight: $regular
						padding-right: 0.5rem
			&__close_stan
				span
					font-size: 1.4em
					font-weight: $regular

			&-round_week
				display: flex
				width: 100%
				z-index: 6
				height: 100%
				.ge-btn
					height: 3.6em
					flex-grow: 1
					flex-basis: 50%
	&-opt
		display: flex
		font-weight: $bold 
		justify-content: space-between
		align-items: center
		padding: 0.8rem 1rem
		box-sizing: border-box
		border: none
		color: $black
		border-bottom: 1px solid $light-grey
		cursor: pointer
		height: 40px
		z-index: 1
		position: relative
		&::before
			content: ""
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			background: $gradient-selected
			opacity: 0
			transition: opacity 0.2s ease-in
			z-index: -1
		@media (hover: hover) and (pointer: fine)
			&:hover
				&::before
					opacity: 1
				color: $white
				.ge-match-time-info
					transition: all 0.2s linear
					color: $white
		&--sel
			background-image: $gradient-selected
			color: $white
			transition: all 0.2s linear

		&--current
			background-image: $gradient-current
			color: $black

		span
			// padding-left: 0.5rem
		img
			max-height: 25px
		&-round_btn
			display: flex
			align-items: center
			padding: 0
			background: none
			border: none
			outline: inherit
			cursor: pointer
			color: $white
		
		&--round
			display: none
			@media (hover: hover) and (pointer: fine)
				&:hover
					background: $primary-color
			+breakpoint(large)
				display: flex
		
	&-cont
		position: absolute
		top: 0
		right: 0
		width: 100%
		align-content: start
		transition: 1s
		background-color: $white
		display: inline-grid
		align-items: start
		grid-auto-flow: row
		&--day
			grid-gap: 1rem
			padding-top: 2rem
		&-wrap
			padding-top: 0
			width: 100%
			// position: fixed
			position: relative
			z-index: 2
			height: calc(100% - var(--tb-height))
			bottom: 0
			// height: 100%
			overflow-y: auto
			margin-top: var(--tb-height)
			+scrollbar
			+breakpoint(large)
				height: calc(100vh - var(--tb-height))
				margin-top: 0
			// 	position: relative	

			&::before
				content: ""
				padding-top: var(--tb-height)
		&-abs
			position: absolute
			width: 100%
			top: 0
			right: 0
		&__calendar
			color: $black
			display: grid
			grid-auto-flow: column
			grid-gap: 0.5rem
			padding-right: calc(1rem - 8px)
			// margin-top: 1rem
			justify-content: flex-end
			align-items: center
			font-size: 0.95rem
			i
				font-size: 1rem

		&--big
			padding-top: 1rem
			grid-gap: 3rem

	&-day
		display: grid
		grid-auto-flow: row
		padding-bottom: 3rem

		& > span
			// font-size: 1.2rem
			font-size: 1.2em
			font-weight: $bold  
		&-match
			padding: 1em 0
			box-sizing: border-box
			width: 100%
			//-height: 70px
			color: initial
			text-decoration: none
			display: flex
			justify-content: center
			cursor: pointer
			border-top: 1px solid $light-grey
			position: relative
			z-index: 1
			&:last-child
				border-bottom: 1px solid $light-grey

			&::before
				content: ""
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background: $gradient-selected
				opacity: 0
				transition: opacity 0.2s ease-in
				z-index: -1
			@media (hover: hover) and (pointer: fine)
				&:hover
					&::before
						opacity: 1
					color: $white
					.ge-match-time-info
						transition: all 0.2s linear
						color: $white
	
			&--sel
				background: $gradient-selected
				transition: background 2s linear
				color: $white
				.ge-match-time-info
					transition: all 0.2s linear
					color: $white
					&__live
						background: $white


			
			&--current
				background-image: $gradient-current

		&-sponsor
			box-sizing: border-box
			padding: 3rem 0
			display: flex
			justify-content: center
			
			img
				max-width: 100px
				max-height: 50px
				object-fit: contain
				
		&__date
			padding: 0 1rem
				bottom: 0.5rem
				
.no-pointer-events-disabled
	pointer-events: none



</style>

<script>

import api from "@/api/index"

import NavDay from "@/components/subcomponents/nav/NavDay.vue"
import NavRound from "@/components/subcomponents/nav/NavRound.vue"
import NavWeek from "@/components/subcomponents/nav/NavWeek.vue"
import NavCompetition from "@/components/subcomponents/nav/NavCompetition.vue"
import NavYear from "@/components/subcomponents/nav/NavYear.vue"
import StandingsTable from "@/components/subcomponents/StandingsTable.vue"
import Loading from "@/components/subcomponents/general/Loading.vue"
import NavSlider from "@/components/subcomponents/nav/NavSlider.vue"
import NavTop from "@/components/subcomponents/nav/NavTop.vue"
import { directive as onClickaway } from 'vue-clickaway'


export default {
	name: "Nav",
	props: {
		isWidget: Boolean
	},
	data() {
		return {
			dragged: false,
			dragTimeout: null,
			nav_state: 1,
			show_match: 1,
			show_standings: 0,
			actual: {
				year: '',
				competition: '',
				edition: '',
				round: '',
				week: '',
				phase: ''
			},
			match_list: [],
			week_list: [],
			round_list: [],
			competition_list: [],
			year_list: [],
			standings: [],
			loading: true,
			competitionImage: '',
			competitionName: '',
			nav: {
				week_list: [],
				round_list: [],
				actual: {},
				active_element: false,
			}
		}
	},
	computed: {
		root_element() {
			if (this.$store.state.general.isWidget) {
				if (document.querySelector('widget-results')) {
					return document.querySelector('widget-results').shadowRoot
				}
			}
			return document
		},
		getCompetitionImage() {
			if (this.$data.actual.competition.id == 3) {
				return "/competicion/supercopa-logo.png"
			} else if (this.$data.actual.competition.id == 2) {
				return "/competicion/copa-del-rey-logo.png"
			} else if (this.$data.actual.competition.id == 10) {
				return "/competicion/minicopa-endesa-logo.png"
			} else {
				return this.$data.competitionImage
			}
		},
		getJornadaorRonda() {

			if (this.competitionName) {
				if (this.competitionName.includes("Liga")) {
					return "Jornada"
				} else {
					return "Ronda"
				}
			} else {
				return ""
			}
		},
		selectorRoundWeek() {
			return this.$store.state.general.selectorRoundWeek
		},
		isShowingDays() {
			return this.nav_state == 1
		},

		isShowingRounds() {
			return this.nav_state == 2
		},
		refreshSignal() {
			return this.$store.state.general.refreshSignal
		},
		isMatchSelected() {
			if (this.$route) {
				return !!this.$route.params.id_match
			} else {
				return false
			}
		},
		isSmall() {
			return this.$store.state.general.isSmall
		},
		isMedium() {
			return this.$store.state.general.isMedium
		},
		isLarge() {
			return this.$store.state.general.isLarge
		},
		showNav() {
			return this.$store.state.general.showNav
		},
		displayNav() {
			if (!this.$route) return true
			let displayNavValue = (this.showNav || this.isLarge || (!this.isMatchSelected && this.isSmall) || (!this.isMatchSelected && this.isMedium))

			// let body = document.getElementsByTagName("BODY")[0]

			// if (displayNavValue){

			// 	body.setAttribute("showNav","True")
			// }
			// else{
			// 	body.removeAttribute("showNav")
			// }
			return displayNavValue
		},
		error() {
			return this.$store.state.general.error
		},
		showNavigation() {
			if (this.isLarge) {
				return true
			} else if (this.isSmall) {
				return !this.isMatchSelected && !this.hideNav
			} else {
				return false
			}
		}
	},
	methods: {
		setActiveElementTrue() {
			this.nav.active_element = true
		},
		selectRounds() {
			this.updateRoundList(this.$data.actual.edition.id, this.$data.actual.competition.id)
			this.$store.commit('general/setSelectorRoundWeek', 'round')
		},
		selectWeeks() {
			this.updateWeekList(this.$data.actual.edition.id, this.$data.actual.competition.id)
			this.$store.commit('general/setSelectorRoundWeek', 'week')
		},
		getActual() {
			this.$data.loading = true
			api.competition.getActual()
				.then((data) => {
					this.$data.actual = data.actual
					this.$data.competitionImage = data.competitionImage
					this.$data.competitionName = data.competitionName
					this.updateCompetitionList(data.actual.year)
					api.competition.getCurrentRound(data.actual.edition.id, data.actual.competition.id)
						.then((data) => {
							this.$store.commit('general/setActualCompRound', data.id)
						})
					api.competition.getCurrentWeek(data.actual.edition.id, data.actual.competition.id)
						.then((data) => {
							this.$store.commit('general/setActualCompWeek', data.id)
						})
					this.$data.loading = false
				}
				)
		},
		getNavActual() {
			this.$data.loading = true
			api.competition.getActual()
				.then((data) => {
					this.$data.nav.actual = data.actual
					this.$data.loading = false
				}
				)
		},
		getActualFromMatch(idMatch) {
			api.competition.getActualFromMatch(idMatch)
				.then((data) => {
					if (data) {
						if (data.actual.edition.id >= this.$store.state.general.oldestACBLeagueEdition) {
							this.$data.actual = data.actual
							this.$data.competitionImage = data.competitionImage
							this.$data.competitionName = data.competitionName
							this.updateCompetitionList(data.actual.year)
							api.competition.getCurrentRound(data.actual.edition.id, data.actual.competition.id)
								.then((data) => {
									this.$store.commit('general/setActualCompRound', data.id)
								})
							api.competition.getCurrentWeek(data.actual.edition.id, data.actual.competition.id)
								.then((data) => {
									this.$store.commit('general/setActualCompWeek', data.id)
								})
							this.$store.commit('general/setError', false)
							return 1
						} else {
							this.$store.commit('general/setError', true)
							this.getActual()
						}
					} else {
						this.$store.commit('general/setError', true)
						this.getActual()
					}
				})
		},
		updateMatchList(y, c, r) {
			api.competition.getMatchList(y, c, r)
				.then((data) => {
					this.$data.match_list = data
					this.$data.loading = false
				}
				)
		},
		updateMatchListByWeek(y, c, w) {
			api.competition.getMatchListByWeek(y, c, w)
				.then((data) => {
					this.$data.match_list = data
					this.$data.loading = false
				}
				)
		},
		updateRoundList(y, c) {
			this.$data.loading = true
			this.$data.round_list = []
			this.$data.match_list = []
			// this.$data.standings = []
			let idComp = c ? c : this.$data.actual.competition.id
			api.competition.getRoundList(y, idComp)
				.then((data) => {
					this.$data.round_list = data
					this.$data.loading = false
				}
				)
		},
		updateNavRoundList(y, c) {
			this.$data.loading = true
			this.$data.round_list = []
			let idComp = c ? c : this.$data.actual.competition.id
			api.competition.getRoundList(y, idComp)
				.then((data) => {
					this.$data.nav.round_list = data.sort(function (a, b) {
						return a.num_matchweek - b.num_matchweek;
					});
					this.$data.loading = false
				}
				)
		},
		updateWeekList(y, c) {
			this.$data.loading = true
			this.$data.week_list = []
			this.$data.match_list = []
			// this.$data.standings = []
			let idComp = c ? c : this.$data.actual.competition.id
			api.competition.getWeekList(y, idComp)
				.then((data) => {
					if (!data.length) {
						this.selectRounds()
					} else {
						this.$data.week_list = data
						this.$data.loading = false
					}
				})
		},
		updateNavWeekList(y, c) {
			this.$data.loading = true
			this.$data.week_list = []
			let idComp = c ? c : this.$data.actual.competition.id
			api.competition.getWeekList(y, idComp)
				.then((data) => {
					if (!data.length) {
						this.selectRounds()
					} else {
						this.$data.nav.week_list = data.sort(function (b, a) {
							return b.start_date - a.start_date;
						});
						this.$data.loading = false
					}
				})
		},
		updateCompetitionList(y) {
			this.$data.loading = true
			this.$data.competition_list = []
			api.competition.getCompetitionList(y)
				.then((data) => {
					this.$data.competition_list = data
					if (data.length)
						this.$data.loading = false
				}
				)
		},
		updateYearList() {
			api.competition.getYearList()
				.then((data) => {
					this.$data.year_list = data
					if (data.length)
						this.$data.loading = false
				}
				)
		},
		updateStandings(y, c, r, p) {
			if (
				y == this.$store.state.general.currentACBLeagueEdition &&
				c == this.$store.state.general.currentACBLeagueCompetition
			) {
				api.competition.getActualStandings()
					.then((data) => {
						if (data) {
							this.$data.standings = data
						}
					})
			} else {
				api.competition.getStandings(y, c, r, p)
					.then((data) => {
						if (data) {
							this.$data.standings = data
						}
					})
			}
		},
		goNavDay(roundweek, changeNavState = true) {
			if (this.selectorRoundWeek == 'round') {
				this.$data.actual.round.id = roundweek.id
				this.$data.actual.round.matchweek_number = roundweek.num_matchweek
				this.$data.actual.phase.id = roundweek.id_phase
				this.$data.match_list = []
				this.updateMatchList(this.$data.actual.edition.id, this.$data.actual.competition.id, this.$data.actual.round.id)
				if (changeNavState) {
					this.prevNavState()
				}
			} else {
				this.$data.actual.week.id = roundweek.id
				this.$data.actual.week.start_date = roundweek.start_date
				this.$data.actual.week.description = roundweek.description
				this.$data.actual.phase.id = roundweek.id_phase
				this.$data.match_list = []
				this.updateMatchListByWeek(this.$data.actual.edition.id, this.$data.actual.competition.id, this.$data.actual.week.id)
				if (changeNavState) {
					this.prevNavState()
				}
			}
			if (this.$data.show_standings) this.$data.show_standings = false
		},
		goNavRound(competition) {
			api.competition.getCurrentRound(competition.id, competition.id_competition)
				.then((data) => {
					this.$store.commit('general/setActualCompRound', data.id)
				})
			this.$data.actual.competition.id = competition.id_competition
			this.$data.competitionImage = competition.competition.url_image_negative
			this.$data.competitionName = competition.competition.official_name
			this.$data.actual.edition.id = competition.id
			this.updateRoundList(this.$data.actual.edition.id, this.$data.actual.competition.id)
			this.prevNavState()
		},
		goNavWeek(competition) {
			api.competition.getCurrentWeek(competition.id, competition.id_competition)
				.then((data) => {
					this.$store.commit('general/setActualCompWeek', data.id)
				})
			this.$data.actual.competition.id = competition.id_competition
			this.$data.competitionImage = competition.competition.url_image_negative
			this.$data.competitionName = competition.competition.name
			this.$data.actual.edition.id = competition.id
			this.updateWeekList(this.$data.actual.edition.id, this.$data.actual.competition.id)
			this.prevNavState()
		},
		goNavCompetition(year) {
			this.$data.actual.year = year
			this.updateCompetitionList(this.$data.actual.year)
			this.prevNavState()
		},
		nextNavState() {
			this.nav.active_element = false
			if (this.$data.nav_state < 4) {
				++this.$data.nav_state
			}
			if (this.$data.show_standings) {
				this.toggleStandings()
			}
		},
		prevNavState() {
			--this.$data.nav_state
		},
		toggleStandings() {
			if (this.$data.standings.length == 0) {
				this.updateStandings(this.$data.actual.edition.id, this.$data.actual.competition.id, this.$data.actual.round.id, this.$data.actual.phase.id)
			} else {
				this.$data.show_standings = !this.$data.show_standings
			}

		},
		clickOutStandings() {
			this.toggleStandings()
		},
		parseWeekDate(date) {
			let datetime = new Date(date * 1000)
			return `${datetime.getDate()}/${datetime.getMonth() + 1}`
		},
		updateActualStandings() {
			api.competition.getActualStandings()
				.then((data) => {
					if (data) {
						this.$data.standings = data
						this.$data.loading = false
					}
				})
		},
	},
	watch: {
		actual: {
			handler: function (newValue) {
				if (this.selectorRoundWeek == 'round') {
					this.updateMatchList(newValue.edition.id, newValue.competition.id, newValue.round.id)
					this.updateRoundList(newValue.edition.id, newValue.competition.id)
					this.updateActualStandings()
				} else {
					this.updateMatchListByWeek(newValue.edition.id, newValue.competition.id, newValue.week.id)
					this.updateWeekList(newValue.edition.id, newValue.competition.id)
					this.updateActualStandings()
				}
				this.$store.commit('general/setActualState', newValue)

				this.getNavActual()
				this.updateNavWeekList(newValue.edition.id, newValue.competition.id)
				this.updateNavRoundList(newValue.edition.id, newValue.competition.id)
			},
			deep: true
		},
		refreshSignal(newValue) {
			if (newValue) {
				if (this.$data.nav_state == 1 || this.isWidget) {
					if (this.selectorRoundWeek == 'round' && this.$data.actual.round.id) {
						this.updateMatchList(this.$data.actual.edition.id, this.$data.actual.competition.id, this.$data.actual.round.id)
					}
					if (this.selectorRoundWeek == 'week' && this.$data.actual.week.id) {
						this.updateMatchListByWeek(this.$data.actual.edition.id, this.$data.actual.competition.id, this.$data.actual.week.id)
					}
				} else if (this.$data.nav_state == 2 && this.$data.loading) {
					if (this.selectorRoundWeek == 'round') {
						this.updateRoundList(this.$data.actual.edition.id, this.$data.actual.competition.id)
					} else {
						this.updateWeekList(this.$data.actual.edition.id, this.$data.actual.competition.id)
					}
				} else if (this.$data.nav_state == 3 && this.$data.loading) {
					this.updateCompetitionList(this.$data.actual.year)
				} else if (this.$data.loading) {
					this.updateYearList()
				}
			}
		}
	},
	async beforeMount() {
		if (this.isWidget) {
			this.$store.commit('general/setIsWidget', true)
			this.getActual()
			this.$store.commit('general/toggleNav')
			this.$store.commit("general/setRefreshSignal");
		} else {
			if (this.$route) {
				if (this.$route.params.id_match) {
					this.getActualFromMatch(this.$route.params.id_match)
					if (!this.$store.state.general.isApp) {
						this.$store.commit('general/setNav', 0)
					}
				} else {
					this.getActual()
					this.$store.commit('general/toggleNav')
				}
			} else {
				this.getActual()
				this.$store.commit('general/setNav', 0)
			}
			this.updateYearList()

		}
	},
	directives: {
		onClickaway: onClickaway,
	},
	components: {
		NavSlider,
		NavDay,
		NavWeek,
		NavRound,
		NavCompetition,
		NavYear,
		StandingsTable,
		Loading,
		NavTop
	}
}

</script>