import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import obj from "svgo/lib/svgo/jsAPI"

// const BASE_URL_OPENAPILIVE = "https://api2-devel.acb.com/api/v1/openapilive";
// const BASE_URL_OPENAPI = "https://api2-devel.acb.com/api/v1/openapi";

const BASE_URL_OPENAPILIVE = "https://api2.acb.com/api/v1/openapilive"
const BASE_URL_OPENAPI = "https://api2.acb.com/api/v1/openapi"

const AUTH_TOKEN =
  "H4sIAAAAAAAAA32Ry3aqMBSG36iLi7qOw4oFk9PEQyohZEYilkCirIMF5OkbOmiVQUdZ+/bv798pbrAUkVR7BUEyAhcr0IIzWcoArEDdMBrA9VNxg5BPiQqp1wDGnEGTp/009MIZD4sd0dIsSxFMw7ATUaL2ulWxN7jZGV2poTegehuvrzKiTp7iRniL7zxhoZuzTZl5uhPKTTkD055Q+qTkkR6Pka4y9qNDTDjyu/hg6zxdVnkajq+UazmZ0Bstz7gT5j5HGhmtx4MPa9s/34dyxvUj41LL22OdMOxkqdXxSZN5d9r10BwNDS2v5Vh/zPg8ntD6W9OhwzHVvfDnPU033ZFHL495uinlmTTcsv/q5Yshmc+2GdNB8fXWs32wK3a4Ej7UMx8JT13LBy3LvUdsNXDDjf2P2X3YW6+EgQZUlwEf6gXeIgdvk4W9my52z2pfAReNcY8q1KPte4+CXtn/cub9p/jJef7j/Xvf/j150Sag6C1ctS41qVe3p6Fml5U4gf/xdecli8sn/TA0Eb8CAAA=";

const mediacenter_types = [
  {
    id_type: 1,
    type: "foto_cuerpo",
    ratio: "5-6",
    size: "03",
  },
  {
    id_type: 2,
    type: "audio pronunciacion nombre",
    ratio: "",
    size: "03",
  },
  {
    id_type: 3,
    type: "foto_equipo",
    ratio: "",
    size: "02",
  },
  {
    id_type: 4,
    type: "foto_juego",
    ratio: "",
    size: "02",
  },
  {
    id_type: 5,
    type: "foto_de_cara",
    ratio: "5-6",
    size: "04",
  },
  {
    id_type: 6,
    type: "logo",
    ratio: "",
    size: "05",
  },
  {
    id_type: 7,
    type: "logo_negativo",
    ratio: "",
    size: "05",
  },
];
export { mediacenter_types };

Vue.use(VueAxios, axios);
axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;

const getAPI = async (url, api = "live") => {
  if (api == "live") {
    url = BASE_URL_OPENAPILIVE + url;
  } else {
    url = BASE_URL_OPENAPI + url;
  }
  let response = null,
    tries = 0;
  do {
    if (tries < 1) {
      try {
        response = await Vue.axios.get(url);
        break;
      } catch (err) {
        ++tries;
      }
    } else {
      break;
    }
  } while (!response);
  if (response) {
    return response.data;
  }
};

const parsedQueryString = (params) => {
  return "?" + new URLSearchParams(params).toString();
};

export {
  getAPI,
  parsedQueryString,
  BASE_URL_OPENAPI,
  BASE_URL_OPENAPILIVE,
  AUTH_TOKEN,
};
