// import store from "@/store/index.js"

const state = {
  navActual: {
    year: "",
    competition: "",
    edition: "",
    round: "",
    week: "",
    phase: "",
  },

  isWidget: false,

  actual: {
    year: null,
    competition: null,
    edition: null,
    round: null,
    match: null,
  },

  // Base
  oldestACBLeagueEdition: 84,
  oldestACBLeagueYear: 2019,

  refreshing: false,

  currentACBLeagueEdition: 88,
  currentACBLeagueCompetition: 1,

  selectorRoundWeek: "week",

  actualCompRound: 1,
  actualCompWeek: 1,

  showNav: 0,
  isSmall: null,
  isMedium: null,
  isLarge: null,
  showGoTop: false,

  contentBodyWidth: 0,

  isTop: true,
  scrollTop: 0,
  headHeight: 360,
  // baseHeadHeight: 360,
  collapsedHeight: 117,

  showHomeBanner: true,
  showOvBanner: true,

  refreshSignal: false,
  refreshIntervalId: null,

  requestTimeout: 2000,

  error: false,
  isContentReady: false,
  isHeadReady: false,
  isBoxscoreReady: false,
  isOvLocalReady: false,
  isOvVisitorReady: false,
  isLeadersReady: false,
  isVersusReady: false,

  browser: " ",

  isApp: false,
  navigation_prematch: "",

  yearList: [],
};

const mutations = {
  setBrowser(state, browser) {
    state.browser = browser;
  },
  setIsWidget(state, isWidget) {
    state.isWidget = isWidget;
  },
  setRefreshing(state, val) {
    state.refreshing = val;
  },
  setCollapsedHeight(state, val) {
    state.collapsedHeight = val;
  },
  setShowHomeBanner(state, val) {
    state.showHomeBanner = val;
  },
  setShowOvBanner(state, val) {
    state.showOvBanner = val;
  },
  setVersusReady(state, val) {
    state.isVersusReady = val;
  },
  setLeadersReady(state, val) {
    state.isLeadersReady = val;
  },
  setOvVisitorReady(state, val) {
    state.isOvVisitorReady = val;
  },
  setOvLocalReady(state, val) {
    state.isOvLocalReady = val;
  },
  setBoxscoreReady(state, val) {
    state.isBoxscoreReady = val;
  },
  setHeadReady(state, val) {
    state.isHeadReady = val;
  },
  setContentReady(state, val) {
    state.isContentReady = val;
  },
  setError(state, val) {
    state.error = val;
  },
  setRefreshSignal(state) {
    let refreshTimeSeconds = 20;
    if (!state.refreshIntervalId) {
      state.refreshIntervalId = setInterval(() => {
        state.refreshSignal = !state.refreshSignal;
      }, (refreshTimeSeconds / 2) * 1000);
    }
  },
  clearRefreshSignal(state) {
    if (state.refreshIntervalId) {
      clearInterval(state.refreshIntervalId);
      state.refreshSignal = false;
      state.refreshIntervalId = false;
    }
  },
  deactivateRefreshSignal(state) {
    state.refreshSignal = false;
  },
  activateRefreshSignal(state) {
    state.refreshSignal = true;
  },
  setContentBodyWidth(state, width) {
    state.contentBodyWidth = width;
  },
  setActualState(state, value) {
    state.actual = value;
  },
  setActualCompRound(state, value) {
    state.actualCompRound = value;
  },
  setActualCompWeek(state, value) {
    state.actualCompWeek = value;
  },
  toggleNav(state) {
    state.showNav = !state.showNav;
  },
  setNav(state, val) {
    state.showNav = val;
    let url = new URL(window.location.href);
    if (state.isApp) {
      url.searchParams.set("app", true);
      if (state.showNav) {
        url.searchParams.delete("match");
        window.history.pushState("", "", url);
      } else {
        url.searchParams.set("match", true);
        window.history.pushState("", "", url);
      }
    }
  },
  setScreenSize(state, width) {
    if (width < 640) {
      state.isSmall = 1;
      state.isMedium = 0;
      state.isLarge = 0;
    } else if (width < 1024) {
      state.isSmall = 0;
      state.isMedium = 1;
      state.isLarge = 0;
    } else {
      state.isSmall = 0;
      state.isMedium = 0;
      state.isLarge = 1;
    }
  },
  setTop(state, value) {
    state.isTop = value;
  },
  setScrollTop(state, value) {
    state.scrollTop = value;
    // if (value > 50) {
    //     state.showGoTop = 1
    // } else {
    //     state.showGoTop = 0
    // }
  },
  setHeadHeight(state, value) {
    state.headHeight = value;
  },
  // setBaseHeadHeight(state) {
  //     if (state.isSmall) {
  //         if (store.state.match.quarterNum <= 4) {
  //             state.baseHeadHeight = 380
  //         } else {
  //             state.baseHeadHeight = 430
  //         }
  //     } else {
  //         if (store.state.match.quarterNum <= 4) {
  //             state.baseHeadHeight = 360
  //         } else {
  //             state.baseHeadHeight = 410
  //         }
  //     }
  // },
  setLang(state, new_lang) {
    state.lang = new_lang;
  },
  setSelectorRoundWeek(state, value) {
    state.selectorRoundWeek = value;
  },
  setIsApp(state, value) {
    state.isApp = value;
  },
  setNavigationPrematch(state, value) {
    state.navigation_prematch = value;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
